'use client'

import React, {useEffect, useState} from "react";
import {getWeather} from "@/api/api";
import {weatherIcons} from "@/config/utils";
import Alerts from "@/components/alerts";

const Weather = ({alerts, data}: { alerts: any, data: any }) => {
    const [weather, setWeather] = useState(data);

    useEffect(() => {
        const fetchWeather = async () => {
            const weatherData = await getWeather();
            setWeather(weatherData);
        };

        fetchWeather();
        const interval = setInterval(fetchWeather, 120000);
        return () => clearInterval(interval);
    }, []);

    const weatherCode = weather.current_condition[0].weatherCode as keyof typeof weatherIcons;
    const IconComponent = weatherIcons[weatherCode];

    return (weather && (<div
        className="p-8 w-full sm:rounded-large dark:bg-gray-50 dark:text-gray-800 sm:border border-y border-gray-200 border-opacity-25 shadow-lg hover:shadow-xl transition-shadow">
        <div className="text-2xl flex flex-row items-center justify-center text-center gap-x-2 font-bold">
            <span>Štivor</span>
            <div className="relative flex h-3 w-3">
                <div
                    className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></div>
                <div className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></div>
            </div>
        </div>

        <div className="flex flex-col justify-center space-y-2">
            <div className="flex flex-row justify-center items-center space-x-4">
                {IconComponent && <IconComponent className="w-24 h-24 p-2 fill-current" />}
                <span className="font-bold text-8xl">{weather.current_condition[0].temp_C}°</span>
            </div>
            <div className="text-center text-xl font-bold">
                {weather.current_condition[0].lang_it[0].value.trim()}
            </div>
        </div>
        <div className="flex justify-between m-8 space-x-4 dark:text-gray-600">
            {weather.weather.map((day: any, index: number) => {
                const weatherCode = day.hourly[4].weatherCode as keyof typeof weatherIcons;
                const IconComponent = weatherIcons[weatherCode];
                return (<div key={index} className="flex flex-col items-center space-y-1">
                                    <span className="uppercase">
                                        {new Date(day.date).toLocaleDateString('it-IT', {weekday: 'short'}) === new Date().toLocaleDateString('it-IT', {weekday: 'short'}) ? 'Oggi' : new Date(day.date).toLocaleDateString('it-IT', {weekday: 'short'})}
                                    </span>
                    {IconComponent && <IconComponent className="w-8 h-8 fill-current" />}
                    <div className="flex flex-row items-center gap-x-2">
                        <span className="text-blue-400">{day.mintempC}</span>/
                        <span className="text-red-400">{day.maxtempC}</span>
                    </div>
                </div>);
            })}
        </div>
        {alerts && <Alerts alerts={alerts} />}
    </div>));
};

export default Weather;